.search-screen-body {
    position: relative;

    .top-container {
        margin-bottom: 26px;
        position: relative;

        .main-title {
            font-weight: 700;
            font-size: 30px;
            line-height: 40px;

            @include media-breakpoint-up(lg) {
                font-size: 40px;
                line-height: 52px;
            }
        }

        select {
            cursor: pointer;
            margin-top: 10px;
            max-width: 230px;

            @include media-breakpoint-up(lg) {
                margin-top: 0;
            }

            &:focus {
                outline: none;
            }

            option {
                padding-left: 10px;
                padding-right: 10px;
            }
        }
    }

    .search-result-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media screen and (min-width: 2000px) {
            flex: 0 0 100%;
        }

        .open-menu {
            display: none;

            @include media-breakpoint-down(md) {
                display: flex;
                font-size: 24px;
                color: $base;
            }
        }

        .pagination {
            margin-top: 10px;
        }
    }

    .search-results {
        padding: 40px 20px;

        @include media-breakpoint-up(sm) {
            padding: 0;
            padding-top: 40px;
            padding-bottom: 40px;
        }

        @include media-breakpoint-up(lg) {
            margin-right: 20px;
            padding-top: 20px;
            padding-bottom: 20;
        }

        @include media-breakpoint-up(xl) {
            margin-left: 20px;
        }

        @media screen and (min-width: 2000px) {
            margin: auto;
        }

        .row {
            width: 100%;
            margin: auto;

            > div {
                margin-bottom: 21px;
            }
        }

        .ship-box {
            width: 100%;
            box-shadow: 0px 0px 33.8457px rgba(0, 0, 0, 0.1);
            border-radius: 10px;

            .image-container {
                position: relative;

                img {
                    height: 180px;
                    object-fit: cover;
                    width: 100%;
                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;
                    cursor: pointer;
                }

                .discount {
                    position: absolute;
                    right: -10px;
                    bottom: 18px;
                    border-radius: 8.3px;
                    padding: 5px 20px 5px 10px;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 23px;
                }

                .rating {
                    position: absolute;
                    top: 12px;
                    left: 12px;
                    font-size: 0;
                    background-color: rgba(255, 255, 255, 0.6);
                    padding: 5px 6px;
                    border-radius: 140px;
                }
            }

            .box-content {
                padding: 13px 22px;

                .title {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 40px;
                    margin-bottom: 4px;
                    // margin-bottom: 8px;
                }

                p.location {
                    margin-bottom: 8px;
                    font-size: 14px;
                }

                p.year,
                p.availability {
                    font-size: 14px;
                    line-height: 130%;
                    color: $base;
                }

                .price-container {
                    display: flex;
                    align-items: center;

                    .price-list-price {
                        margin-left: 5px;
                    }

                    p.price {
                    font-weight: 700;
                    font-size: 30px;
                    line-height: 33px;
                    margin-top: 18px;
                    display: inline-block;
                    border-radius: 10px;
                    padding: 5px 0px;
                    color: $gold;
                    background-size: 100% 100%;
                    margin-bottom: 15px;
                    }

                    .old-price {
                        font-size: 20px;
                        text-decoration: line-through;
                        margin-right: 16px;
                    }
                }

                

                .view-btn {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 33px;
                    @include transition;

                    &:hover {
                        color: $base !important;
                        @include transition;
                    }

                    svg {
                        margin-left: 6px;
                    }
                }
            }
        }
    }
}

.pagination {
    margin-top: 50px;

    .pages {
        margin: auto;

        svg {
            margin-left: 10px;
            margin-right: 10px;
            transition: $transition;

            &:hover {
                color: $gold !important;
                transition: $transition;
            }
        }
    }
}

.active-filters-container {
    margin: 20px 0px !important;
    .active-title {
        margin-right: 10px;
    }

    span {
        background-color: rgb(233, 233, 233);
        margin: 0px 10px 10px 10px;
        padding: 10px;
        border-radius: 10px;
        font-size: 14px;
    }

    .clearbutton {
        flex-grow: 1;
        border: none;

        font-size: 16px;
        font-weight: 600;
        padding: 10px;
        max-width: 200px;

        @include transition;

        &:hover {
            background-color: $gold !important;
            @include transition;
        }
    }
}
