.contacts {
    padding: 40px 20px;

    @include media-breakpoint-up(lg) {
        padding: 80px 20px;
    }

    .content {
        max-width: 1160px;
        margin: auto;

        .row {
            width: 100%;
            margin: auto;
            padding-bottom: 10px;

            @include media-breakpoint-up(lg) {
                padding-bottom: 10px;
            }
        }

        .main-title {
            margin-bottom: 40px;
            font-weight: 600;

            @include media-breakpoint-up(lg) {
                font-weight: 700;
                font-size: 40px;
                line-height: 52px;
                margin-bottom: 51px;
            }
        }

        .content-item {
            padding: 0;
            margin-bottom: 10px;

            .icon {
                margin-right: 10px;
            }

            p {
                line-height: 21px;
            }
        }
    }
}
