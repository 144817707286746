.reservation-data-container {
    padding: 0 20px;

    .content {
        max-width: 1160px;
        margin: auto;
        padding-top: 15px;
        padding-bottom: 80px;

        .back-btn {
            font-weight: 600;
            font-size: 14px;
            line-height: 33px;
            transition: $transition;

            &:hover {
                color: $base !important;
                transition: $transition;
            }

            svg {
                margin-right: 6.5px;
            }
        }

        .row {
            width: 100%;
            margin: auto;
            margin-top: 20px;

            @include media-breakpoint-up(md) {
                margin-top: 50px;
            }

            > div {
                padding: 0;
            }

            .details-container {
                @include media-breakpoint-up(md) {
                    margin-left: 10px;
                }

                .detail-box {
                    padding-bottom: 16px;
                    margin-bottom: 16px;
                    border-bottom: 2px solid #ddd;

                    .title {
                        font-size: 16px;
                        line-height: 16px;
                        font-weight: 600;
                        margin-bottom: 16px;
                    }

                    p {
                        color: $base;
                    }
                }
            }
        }

        .lds-dual-ring {
            width: 100%;
            height: 80px;
            margin-left: 45%;
        }
        .lds-dual-ring:after {
            content: " ";
            display: block;
            width: 64px;
            height: 64px;
            margin: 8px;
            border-radius: 50%;
            border: 6px solid #fff;
            border-color: $lightBlue transparent $lightBlue transparent;
            animation: lds-dual-ring 1.2s linear infinite;
        }
        @keyframes lds-dual-ring {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }
    }
}
