.main-container {
    background: linear-gradient(180deg, rgba(13, 28, 61, 0.6) 0%, rgba(13, 28, 61, 0) 47.4%), url(../../assets/images/main.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    .logo {
        object-fit: cover;
        height: 40px;
        display: none;

        @include media-breakpoint-up(lg) {
            display: block;
        }

        &.mobile {
            display: block;

            @include media-breakpoint-up(lg) {
                display: none;
            }
        }
    }

    &.search-results {
        .search-container {
            @include media-breakpoint-up(md) {
                padding-top: 100px;
            }

            @include media-breakpoint-up(lg) {
                padding-top: 0;
            }

            .title {
                display: none;
            }

            .content {
                padding-bottom: 50px;

                @include media-breakpoint-up(md) {
                    padding-bottom: 90px;
                }

                @include media-breakpoint-up(lg) {
                    padding-top: 70px;
                    padding-bottom: 60px;
                }
            }
        }
    }

    &.short {
        background: linear-gradient(180deg, rgba(13, 28, 61, 0.6) 0%, rgba(13, 28, 61, 0.2) 100%), url(../../assets/images/main.png);
        background-position: 50% 0%;
        background-size: cover;
        background-repeat: no-repeat;

        nav.navbar {
            margin-bottom: 0;
            box-shadow: 0px 3px 20px #00000014;

            @include media-breakpoint-up(lg) {
                box-shadow: none;
            }
        }
    }
}

nav.navbar {
    background-color: $secondary;
    padding: 10px 15px;
    box-shadow: 0px 3px 20px #00000014;

    @include media-breakpoint-up(lg) {
        margin-bottom: 0;
        padding: 22px 15px;
        background-color: transparent;
        box-shadow: none;
    }

    .container-fluid {
        max-width: 1160px;
        margin: auto;
        padding: 0;
    }

    .navbar-brand {
        order: 2;
        font-size: 20px;
        line-height: 40px;
        font-weight: 600;
        color: $base;
        text-transform: uppercase;

        @include media-breakpoint-up(lg) {
            order: initial;
            color: $secondary;
        }
    }

    // Collapse navbar //
    .collapse-navbar {
        @include media-breakpoint-down(md) {
            position: fixed;
            top: 0;
            left: 0;
            width: 85%;
            max-width: 400px;
            height: 100vh;
            transform: translateX(-150%);
            background-color: $secondary;
            z-index: 100;
            padding: 20px;
            padding-top: 40px;
            text-align: center;
            @include transition;

            .nav-link {
                color: $base !important;
                font-weight: 600;
                font-size: 18px;
                padding: 16px;
                border-bottom: 1px solid #ddd;
            }

            .language-container {
                padding: 16px;

                p {
                    color: $base !important;
                    font-weight: 600;

                    &::after {
                        background-color: $base !important;
                    }
                }
            }

            &.show {
                transform: translateX(0);
                @include transition;
            }
        }

        .navbar-close-btn {
            font-size: 22px;
            position: absolute;
            right: -30px;
            top: 5px;
        }
    }
    ////

    .navbar-nav {
        @include media-breakpoint-up(lg) {
            margin-right: 48px;
        }
    }

    .navbar-toggler {
        color: $white;
        border: none;
    }

    a.nav-link {
        padding-left: 16px !important;
        padding-right: 16px !important;
        font-weight: 300;
        @include transition;

        &:hover {
            color: $gold !important;
            @include transition;
        }
    }

    .language-container {
        padding: 8px 16px;

        @include media-breakpoint-up(lg) {
            padding: 0;
            margin-bottom: 0;
        }

        p.hu {
            margin-right: 20px;
            position: relative;

            &::after {
                content: "";
                width: 1px;
                height: 18px;
                background-color: $secondary;
                display: inline-block;
                position: absolute;
                right: -10px;
                bottom: 2px;
            }
        }

        p {
            cursor: pointer;
        }

        p.active {
            color: $gold !important;
        }
    }

    .login-btn {
        padding: 10px 20px;
        font-size: 16px;
        font-weight: 600;
        display: inline-block;
        width: 100%;
        margin-top: 16px;
        margin-right: 50px;
        @include transition;

        @include media-breakpoint-up(lg) {
            width: auto;
            margin-top: 0;
        }

        &:hover {
            background-color: $base !important;
            text-decoration: none;
            @include transition;
        }
    }
}

.logout-button-group {
    svg {
        padding-top: 5px;
        margin-right: 20px;
        color: $gold;
        transition: $transition;
    }

    svg:hover {
        color: $secondary;
        cursor: pointer;
        transition: $transition;
    }
}
