.search-container {
    padding: 0 20px;
    padding-top: 60px;

    .content {
        max-width: 1160px;
        margin: auto;
        padding-bottom: 60px;

        @include media-breakpoint-up(md) {
            padding-bottom: 40px;
        }

        @include media-breakpoint-up(lg) {
            padding-top: 118px;
            padding-bottom: 73px;
        }

        .title {
            font-weight: 700;
            margin-bottom: 34px;
            text-align: center;

            @include media-breakpoint-up(md) {
                text-align: left;
                font-size: 50px;
            }
            @include media-breakpoint-up(lg) {
                font-size: 60px;
                line-height: 78px;
            }
        }

        .row {
            width: 100%;
            margin: auto;

            > div {
                padding-left: 16px;
                padding-right: 16px;

                &:first-child {
                    @include media-breakpoint-up(md) {
                        padding-left: 0;
                    }
                }
            }
        }

        .search-item-container {
            @include media-breakpoint-up(md) {
                width: 23%;
            }

            &.place {
                position: relative;
                @include media-breakpoint-up(xl) {
                    width: 448px;
                }
            }

            &.time {
                .datepicker-label {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin: 0px;
                    cursor: pointer;

                    .chevron {
                        margin-left: 5px;
                    }
                }

                @include media-breakpoint-up(xl) {
                    width: 263px;
                }
            }

            &.bed {
                @include media-breakpoint-up(xl) {
                    width: 242px;
                }
            }

            &.search-btn-container {
                @include media-breakpoint-up(xl) {
                    width: auto;
                }
            }
        }

        .search-item {
            padding: 8px 20px;
            margin-bottom: 10px;

            @include media-breakpoint-up(lg) {
                margin-bottom: 0;
            }

            .item-title svg {
                font-size: 22px;
            }

            label {
                font-size: 16px;
                font-weight: 400;
                line-height: 40px;
                margin-left: 11px;
                margin-right: 22px;

                @include media-breakpoint-up(lg) {
                    margin-right: 11px;
                }
            }

            .minus,
            .plus {
                cursor: pointer;
                margin-top: 5px;
            }


            .minus:hover,
            .plus:hover {
                color: grey !important;
            }

            .counter {
                width: 22px;
                margin: auto;
            }

            .bed-counter {
                margin-left: 70px;
            }

            .react-datepicker__input-container{
                input{
                    max-width: 100px;
                }
            }

            input {
                border: none;
                width: 100%;

                &#bed {
                    width: 40px;
                    text-align: center;

                    &::placeholder {
                        color: $gold;
                    }
                }

                &:focus-visible {
                    outline: none;
                }

                &::placeholder {
                    color: $lightBlue;
                    font-weight: 300;
                }
            }

            input[type="number"]::-webkit-inner-spin-button,
            input[type="number"]::-webkit-outer-spin-button {
                -webkit-appearance: none;
            }
        }

        .search-btn {
            flex-grow: 1;
            border: none;

            font-size: 16px;
            font-weight: 600;
            line-height: 40px;
            padding: 4px 24px;
            width: 100%;
            @include transition;

            &:hover {
                background-color: $gold !important;
                @include transition;
            }

            @include media-breakpoint-up(lg) {
                padding: 8px 24px;
            }

            @include media-breakpoint-up(xl) {
                width: auto;
                padding: 8px 25px;
                max-width: 112px;
            }
        }
    }
}

.loc-selector {
    position: absolute;
    left: 23%;
    top: 97%;
    width: 347px;
    background-color: white;
    font-size: 15px;
    z-index: 10;
    text-align: left;

    .location-select {
        background-color: white;
        padding: 5px 10px 5px 10px;
    }

    .location-select:hover {
        background-color: $gold;
        cursor: pointer;
    }
}

.react-datepicker-ignore-onclickoutside {
    cursor: pointer;
}
