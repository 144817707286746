.social-container {
    background-image: url(../../assets/images/social.png);
    background-repeat: no-repeat;
    background-position: 70%;
    background-size: cover;
    padding: 40px 20px;

    @include media-breakpoint-up(lg) {
        padding: 57px 20px;
    }
    @include media-breakpoint-up(xl) {
        background-position: center 10%;
    }

    .content {
        max-width: 1160px;
        margin: auto;

        .main-title {
            font-weight: 600;
            max-width: 300px;
            margin-bottom: 20px;

            @include media-breakpoint-up(lg) {
                font-size: 60px;
                line-height: 78px;
                font-weight: 700;
                max-width: 650px;
                margin-bottom: 34px;
            }
        }

        .subtitle {
            font-size: 20px;
            line-height: 25px;
            margin-bottom: 40px;
            font-weight: 400;

            @include media-breakpoint-up(lg) {
                font-size: 30px;
                line-height: 39px;
            }
        }

        .social-icons {
            .icon {
                border-radius: 50%;
                width: 40px;
                height: 40px;
                background-color: #33456f;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 12px;
                @include transition;

                @include media-breakpoint-up(lg) {
                    margin-right: 24px;
                }

                &:hover {
                    background-color: $gold;
                    @include transition;

                    svg {
                        color: $secondary;
                        @include transition;
                    }
                }
            }
            svg {
                font-size: 22px;
                color: #9ed2e0;
                @include transition;
            }
        }
    }
}
