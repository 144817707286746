.react-datepicker {
    .disabled {
        background-color: rgb(245, 245, 245);
        color: rgb(211, 211, 211);
        cursor: default;
        pointer-events: none;
    }
    .disabled:hover {
        background-color: rgb(245, 245, 245);
        color: rgb(211, 211, 211);
        cursor: default;
        pointer-events: none;
    }
}