.payment-popup {
    height: 100%;
    display: flex;
    align-items: center;
    margin-top: 0;
    margin-bottom: 0;

    @include media-breakpoint-up(md) {
        width: 541px;
        max-width: none;
    }

    .modal-content {
        max-height: 98vh;
        overflow-y: scroll;
        padding-bottom: 20px;

        @include media-breakpoint-up(md) {
            overflow-y: auto;
        }
    }

    .modal-header {
        border: 0;
        padding: 0;

        .close {
            margin-top: 10px;
            margin-right: 10px;
            padding: 0;
            color: $base;
            opacity: 1;
            @include transition;

            &:hover {
                color: $gold;
                @include transition;
            }
        }

        .modal-title {
            font-size: 20px;
            line-height: 40px;
            font-weight: 700;
            padding: 35px 20px 29px 20px;

            .logo {
                object-fit: cover;
                height: 40px;
            }

            @include media-breakpoint-up(md) {
                padding: 35px 48px 29px 48px;
            }
        }
    }

    .modal-body {
        padding: 0 20px 21px 20px;

        @include media-breakpoint-up(md) {
            padding: 0 48px 21px 48px;
        }

        .title {
            font-size: 40px;
            line-height: 52px;
            font-weight: 700;
            margin: auto;
        }

        form {
            width: 100%;
            margin: auto;

            .button {
                width: 100%;
            }

            .form-group {
                margin-bottom: 6px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                @include media-breakpoint-up(sm) {
                    flex-direction: row;
                }

                label {
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 40px;
                    width: 100%;
                    margin-right: 8px;
                    margin-bottom: 0;

                    @include media-breakpoint-up(sm) {
                        width: 35%;
                    }
                }

                input {
                    border: 0;
                    width: 100%;

                    @include media-breakpoint-up(sm) {
                        padding: 0 5px;
                        width: 65%;
                    }

                    &::placeholder {
                        color: $lightBlue;
                        font-weight: 300;
                    }

                    &:focus {
                        outline: none;
                    }
                }
            }
        }
    }
}
