.registration-banner {
    background-image: url(../../assets/images/registration.png);
    background-repeat: no-repeat;
    background-position: 55%;
    background-size: cover;
    padding: 40px 20px;

    @include media-breakpoint-up(lg) {
        padding: 74px 20px;
    }

    .content {
        max-width: 1160px;
        margin: auto;

        .main-title {
            font-weight: 600;
            max-width: 300px;
            margin-bottom: 20px;

            @include media-breakpoint-up(lg) {
                font-size: 60px;
                line-height: 78px;
                font-weight: 700;
                max-width: 650px;
                margin-bottom: 19px;
            }
        }

        .subtitle {
            font-size: 20px;
            line-height: 25px;
            margin-bottom: 30px;
            font-weight: 500;
            max-width: 200px;

            @include media-breakpoint-up(md) {
                max-width: 350px;
            }

            @include media-breakpoint-up(lg) {
                max-width: 400px;
                font-size: 30px;
                line-height: 39px;
                max-width: 635px;
                font-weight: 400;
                margin-bottom: 45px;
            }
        }

        .register-btn {
            padding: 8px 18px;
            font-weight: 700;
            font-size: 16px;
            @include transition;

            @include media-breakpoint-up(lg) {
                line-height: 40px;
                padding: 8px 24px;
            }

            &:hover {
                background-color: $base !important;
                @include transition;
            }
        }
    }
}
