.offer-details {
    padding: 0 20px;

    .content {
        max-width: 1160px;
        margin: auto;
        padding-top: 15px;
        padding-bottom: 80px;

        .back-btn {
            font-weight: 600;
            font-size: 14px;
            line-height: 33px;
            transition: $transition;

            &:hover {
                color: $base !important;
                transition: $transition;
            }

            svg {
                margin-right: 6.5px;
            }
        }

        .row {
            width: 100%;
            margin: auto;
            margin-top: 20px;

            @include media-breakpoint-up(md) {
                margin-top: 50px;
            }

            > div {
                padding: 0;
            }

            .carousel-container{
                .selected-image{
                    background-size: contain;
                    height: 300px;

                    @include media-breakpoint-up(lg) {
                        height: 500px;
                    }
                }
            }

            .img-container {
                position: relative;
                margin-bottom: 30px;

                @include media-breakpoint-up(md) {
                    margin-right: 40px;
                    margin-bottom: 0;
                }

                img {
                    width: 100%;
                    border-radius: 10px;
                }

                .discount {
                    position: absolute;
                    top: 20px;
                    left: -15px;
                    font-size: 20px;
                    line-height: 39px;
                    font-weight: 700;
                    padding: 0px 10px;
                    border-radius: 13.5px;

                    @include media-breakpoint-up(md) {
                        font-size: 30px;
                        padding: 9px 17px;
                        top: 28.5px;
                    }
                }
            }

            .details-container {
                @include media-breakpoint-up(md) {
                    margin-left: 10px;
                }

                .year {
                    font-size: 22px;
                    line-height: 40px;
                    font-weight: 600;
                    margin-bottom: 2px;
                }

                .ship-name {
                    font-size: 30px;
                    line-height: 52px;
                    font-weight: 700;
                    margin-bottom: 3px;

                    @include media-breakpoint-up(md) {
                        font-size: 40px;
                    }
                }

                .detail-box {
                    padding-bottom: 16px;
                    margin-bottom: 16px;
                    border-bottom: 2px solid #ddd;

                    .title {
                        font-size: 16px;
                        line-height: 16px;
                        font-weight: 600;
                        margin-bottom: 16px;

                        &.link {
                            cursor: pointer;
                        }

                        svg {
                            margin-left: 5px;
                        }
                    }

                    .equ-list {
                        padding-left: 20px;
                        -webkit-column-count: 2;
                        -moz-column-count: 2;
                        column-count: 2;
                        margin-top: 20px;

                        .equ-item {
                            margin-right: 10px;
                            padding-bottom: 5px;
                        }
                    }

                    .equ-chooser-container {
                        margin-top: 20px;

                        .service-title {
                            font-weight: bold;
                            margin-top: 10px;
                        }

                        .equ-chooser {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            min-height: 30px;
                            margin-top: 5px;

                            .checkbox-square {
                                width: 25px;
                                height: 25px;
                                background-color: #eaedee;
                                border-radius: 7px;
                                margin-bottom: 0;
                                margin-left: 10px;
                                cursor: pointer;

                                + label {
                                    cursor: pointer;
                                }
                            }

                            input[type="checkbox"]:checked + .checkbox-square {
                                background-color: $gold;

                                &::after {
                                    content: "\f00c";
                                    font-family: "FontAwesome";
                                    color: $secondary;
                                }
                            }
                        }
                    }

                    svg {
                        color: $lightBlue;
                        margin-right: 10px;
                    }

                    p {
                        color: $base;
                    }

                    .location {
                        p {
                            line-height: 20.8px;
                        }
                        svg {
                            font-size: 23px;
                        }
                    }

                    .description {
                        line-height: 20.8px;
                    }

                    .information-content {
                        margin-bottom: 15px;

                        &:last-of-type {
                            margin-bottom: 10px;
                        }

                        .info {
                            margin-right: 15px;
                            width: 100px;

                            @include media-breakpoint-up(sm) {
                                margin-right: 20px;
                            }

                            @include media-breakpoint-up(md) {
                                margin-right: 15px;
                            }

                            @include media-breakpoint-up(lg) {
                                margin-right: 20px;
                            }

                            &.bed,
                            &.length {
                                margin-right: 15px;

                                @include media-breakpoint-up(sm) {
                                    margin-right: 35px;
                                }

                                @include media-breakpoint-up(md) {
                                    margin-right: 15px;
                                }

                                @include media-breakpoint-up(lg) {
                                    margin-right: 35px;
                                }
                            }

                            p {
                                font-size: 14px;
                                line-height: 18px;
                            }
                        }

                        .bed svg {
                            font-size: 20px;
                        }

                        .length svg,
                        .height svg {
                            font-size: 23px;
                        }
                    }

                    .availability {
                        p {
                            font-size: 14px;
                            line-height: 18px;
                        }

                        svg {
                            font-size: 19.5px;
                        }
                    }

                    &.price-container {
                        border: none;

                        .price {
                            font-size: 30px;
                            line-height: 40px;
                            font-weight: 700;
                            margin-top: 6px;
                            margin-bottom: 6px;
                        }

                        .text {
                            font-size: 14px;
                            line-height: 18px;
                            margin-bottom: 8px;
                        }
                    }
                }

                .book-btn {
                    border: 2px solid $gold;
                    padding: 6px 24px;

                    line-height: 40px;
                    font-weight: 700;
                    display: block;
                    margin: auto;
                    @include transition;

                    @include media-breakpoint-up(md) {
                        margin: 0;
                    }

                    &:hover {
                        background-color: $gold !important;
                        color: $secondary !important;
                        @include transition;
                    }
                }
            }
        }

        .lds-dual-ring {
            width: 100%;
            height: 80px;
            margin-left: 45%;
        }
        .lds-dual-ring:after {
            content: " ";
            display: block;
            width: 64px;
            height: 64px;
            margin: 8px;
            border-radius: 50%;
            border: 6px solid #fff;
            border-color: $primary transparent $primary transparent;
            animation: lds-dual-ring 1.2s linear infinite;
        }
        @keyframes lds-dual-ring {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }
    }
}

//modal
.detailed-boat-picture {
    max-width: fit-content !important;
    width: 100% !important;

    img {
        width: 100%;
        height: auto;
    }
}