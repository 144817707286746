.password-reset {
    margin: auto;
    text-align: center;
    max-width: 300px;
    padding: 80px 20px;

    @include media-breakpoint-up(lg) {
        padding: 20px;
        height: calc(100vh - 448px);
    }

    .input-group {
        margin-bottom: 15px;

        input {
            width: 100%;
        }

        p {
            font-size: 16px;
            font-weight: 700;
            line-height: 40px;
            text-align: left;
            color: $base;
            margin-bottom: 5px;
        }
    }

    button {
        background-color: $gold;
        color: $secondary;
        border: none;
        padding: 10px 20px;
        font-size: 16px;
        font-weight: 600;
        width: 100%;
        transition: $transition;
        margin-top: 20px;

        &:hover {
            background-color: $base;
            transition: $transition;
        }
    }
}
