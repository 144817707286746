.howitwork {
    padding: 40px 20px;

    @include media-breakpoint-up(lg) {
        padding: 80px 20px;
    }

    .content {
        max-width: 1160px;
        margin: auto;

        .row {
            width: 100%;
            margin: auto;
            padding-bottom: 20px;

            @include media-breakpoint-up(lg) {
                padding-bottom: 80px;
            }
        }

        .main-title {
            margin-bottom: 40px;
            font-weight: 600;

            @include media-breakpoint-up(lg) {
                font-weight: 700;
                font-size: 40px;
                line-height: 52px;
                margin-bottom: 51px;
            }
        }

        .content-item {
            padding: 0;
            margin-bottom: 30px;
            position: relative;
            text-align: center;

            @include media-breakpoint-up(lg) {
                margin-left: auto;
                margin-bottom: 0;
                max-width: 300px;
            }

            @include media-breakpoint-up(xl) {
                margin-left: 55px;
            }

            .step-number {
                font-size: 40px;
                font-weight: 700;
                line-height: 50px;
                color: $base;
                position: absolute;
                top: -15px;
                left: -35px;

                @include media-breakpoint-up(lg) {
                    top: -5px;
                    left: -40px;
                }

                @include media-breakpoint-up(xl) {
                    top: 5px;
                    left: -55px;
                    font-size: 60px;
                }
            }

            .title {
                font-weight: 600;
                margin-bottom: 16px;
                position: relative;
                display: inline-block;

                @include media-breakpoint-up(lg) {
                    font-size: 30px;
                    line-height: 40px;
                    font-weight: 700;
                    display: block;
                }
            }

            p {
                line-height: 21px;
            }
        }

        .more-link {
            &:hover {
                text-decoration: none;
            }
        }

        .more-btn {
            background-color: transparent;

            border: 2px solid $gold;
            padding: 6px 12px;
            font-weight: 600;
            line-height: 40px;
            font-size: 16px;
            display: block;
            max-width: 205px;
            margin: auto;
            @include transition;

            &:hover {
                background-color: $gold;
                color: $secondary !important;
                @include transition;
            }

            @include media-breakpoint-up(lg) {
                padding: 6px 24px;
                max-width: 280px;
            }
        }
    }
}
