.special-banner {
    position: relative;
    background-color: $gold;
    min-height: 200px;

    @include media-breakpoint-up(lg) {
        padding: 70px 20px;
        background-position: center;
    }

    .loader-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .content {
        max-width: 1160px;
        margin: auto;
        padding: 40px 20px;

        .main-title {
            font-weight: 600;
            margin-bottom: 20px;
            max-width: 300px;

            @include media-breakpoint-up(lg) {
                font-size: 60px;
                font-weight: 700;
                line-height: 78px;
                max-width: 635px;
                margin-bottom: 34px;
            }
        }

        .ship-name {
            font-size: 22px;
            font-weight: 700;
            margin-bottom: 5px;

            @include media-breakpoint-up(lg) {
                font-size: 40px;
            }
        }

        .year,
        .date {
            font-size: 14px;
            margin-bottom: 5px;

            @include media-breakpoint-up(lg) {
                font-size: 22px;
            }
        }

        .price {
            font-size: 30px;
            font-weight: 700;
            margin-top: 20px;

            @include media-breakpoint-up(lg) {
                font-size: 60px;
                margin-top: 45px;
            }
        }

        .view-btn {
            padding: 8px 18px;

            font-size: 16px;
            font-weight: 600;
            display: inline-block;
            margin-top: 20px;
            @include transition;

            &:hover {
                background-color: $secondary !important;
                color: $base !important;
                @include transition;
            }

            @include media-breakpoint-up(lg) {
                padding: 8px 24px;
                line-height: 40px;
            }
        }

        .image-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;

            img {
                box-shadow: 0px 0px 40px #fff;
                border-radius: 10px;
                max-width: 526px;
                margin: 40px 0;
                width: 100%;
            }
        }
    }
}
