.terms-of-use {
    padding: 40px 20px;

    @include media-breakpoint-up(lg) {
        padding: 80px 20px;
    }

    .content {
        max-width: 1160px;
        margin: auto;

        .main-title {
            margin-bottom: 40px;
            font-weight: 600;

            @include media-breakpoint-up(lg) {
                font-weight: 700;
                font-size: 40px;
                line-height: 52px;
                margin-bottom: 51px;
            }
        }
    }
}
