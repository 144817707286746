.pagination {
    .info {
        display: inline-block;
        padding: 10px 0;

        @include media-breakpoint-up(sm){
            padding: 20px;
        }
    }
    .pagination-button {
        background-color: Transparent;
        background-repeat: no-repeat;
        border: none;
        cursor: pointer;
        overflow: hidden;
        outline: none;
        transition: $transition;

        &:not(button[disabled]):hover {
            transition: $transition;
            color: $gold;
        }

        &.single {
            font-size: 14px;
        }
    }

    select {
        color: $base;
    }

    div {
        margin: auto;
        padding: 20px;
    }
}

.table-container {
    @include media-breakpoint-up(lg){
        overflow: unset;
    }

    overflow-x: scroll;
    border: 1px solid #ddd;
}

.admin-table {
    width: 100%;

    thead {
        background-color: $base;
        th {
            text-align: center;
            color: $secondary;
            padding: 10px 5px;
        }
    }

    tbody {
        tr {
            border-bottom: 1px solid #ddd;

            &:hover {
                td {
                    color: $gold;
                }
            }
        }

        td {
            text-align: center;
            padding: 10px;
            cursor: pointer;
            transition: $transition;
        }
    }
}
