.profile-data-container {
    max-width: 1160px;
    margin: auto;
    padding: 40px 20px;

    @include media-breakpoint-up(xl) {
        padding: 80px 0;
    }

    .main-title {
        font-size: 30px;
        font-weight: 700;
        line-height: 40px;
        margin-bottom: 40px;

        @include media-breakpoint-up(lg) {
            font-size: 40px;
            margin-bottom: 60px;
            line-height: 52px;
        }
    }

    .btn {
        color: white;
        border: 0;
        width: auto;
        padding: 10px 25.5px;
        font-size: 16px;
        font-weight: 600;
        margin-top: 16px;
        transition: all 0.2s linear;

        &:hover {
            background-color: $lightBlue !important;
            @include transition;
        }
    }

    .userdatas {
        .userdatas-row {
            margin-bottom: 10px;

            svg {
                cursor: pointer;
                transition: $transition;

                &:hover {
                    color: $gold !important;
                    transition: $transition;
                }
            }
        }

        .edit-container {
            margin-bottom: 25px;
            margin-top: 10px;
            padding-top: 5px;

            @include media-breakpoint-up(md) {
                padding-right: 35px;
            }

            @include media-breakpoint-up(lg) {
                padding-right: 55px;
            }

            input {
                border: 0;
                border-bottom: 1px solid $lightBlue;
                margin: 0;
                width: 80%;

                &::placeholder {
                    color: $lightBlue;
                }
            }

            .btn {
                color: white;
                padding: 4px 10px;
                margin-left: 10px;
                border: 0;
                font-size: 16px;
                font-weight: 600;
                transition: $transition;
                border-radius: 0;
                margin-top: 0;

                &:hover {
                    background-color: $base !important;
                    @include transition;
                }
            }
        }

        .key {
            font-size: 16px;
            font-weight: 700;
            line-height: 40px;
            margin-right: 8px;
            margin-bottom: 0;
            text-transform: uppercase;
        }
    }

    .reservations {
        img {
            max-width: 100%;
        }

        p {
            margin-bottom: 5px;

            span {
                font-weight: 600;
            }
        }

        .details {
            margin-top: 15px;
            font-weight: 600;
            transition: $transition;

            &:hover {
                text-decoration: none;
                color: $base !important;
                transition: $transition;
            }

            svg {
                margin-left: 5px;
            }
        }
    }
}
