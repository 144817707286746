.login-popup,
.register-popup,
.forgotpassword-popup {
    height: 100%;
    display: flex;
    align-items: center;
    margin-top: 0;
    margin-bottom: 0;

    @include media-breakpoint-up(md) {
        width: 541px;
        max-width: none;
    }

    .modal-content {
        max-height: 98vh;
        overflow-y: scroll;
        padding-bottom: 20px;

        @include media-breakpoint-up(md) {
            overflow-y: auto;
        }
    }

    .modal-header {
        border: 0;
        padding: 0;

        .close {
            margin-top: 10px;
            margin-right: 10px;
            padding: 0;
            color: $base;
            opacity: 1;
            @include transition;

            &:hover {
                color: $gold;
                @include transition;
            }
        }

        .modal-title {
            font-size: 20px;
            line-height: 40px;
            font-weight: 700;
            padding: 35px 20px 29px 20px;

            .logo {
                object-fit: cover;
                height: 40px;
            }

            @include media-breakpoint-up(md) {
                padding: 35px 48px 29px 48px;
            }
        }
    }

    .modal-body {
        padding: 0 20px 21px 20px;

        @include media-breakpoint-up(md) {
            padding: 0 48px 21px 48px;
        }

        .title {
            font-size: 40px;
            line-height: 52px;
            font-weight: 700;
        }

        .registration-container,
        .login-container {
            margin-bottom: 16px;

            .registration,
            .login {
                line-height: 40px;
                font-size: 16px;
            }

            button {
                padding: 0;
                font-weight: 600;
                @include transition;

                @include media-breakpoint-up(sm) {
                    margin-left: 5px;
                }

                &:hover {
                    color: $lightBlue !important;
                    @include transition;
                }
            }
        }

        form {
            .form-group {
                margin-bottom: 6px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                @include media-breakpoint-up(sm) {
                    flex-direction: row;
                }

                label {
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 40px;
                    width: 100%;
                    max-width: 73px;
                    margin-right: 8px;
                    margin-bottom: 0;

                    @include media-breakpoint-up(sm) {
                        width: 35%;
                    }
                }

                input {
                    border: 0;
                    width: 100%;

                    @include media-breakpoint-up(sm) {
                        padding: 0 5px;
                        width: 65%;
                    }

                    &::placeholder {
                        color: $lightBlue;
                        font-weight: 300;
                    }

                    &:focus {
                        outline: none;
                    }
                }
            }
        }

        .forgot-password-btn {
            @include transition;

            &:hover {
                color: $gold !important;
                @include transition;
            }
        }

        .login-box,
        .register-box {
            margin-top: 30px;

            @include media-breakpoint-up(sm) {
                border-top: 2px solid #ddd;
                padding-top: 20px;
            }

            .error-message {
                margin-bottom: 10px;
                text-align: center;

                @include media-breakpoint-up(sm) {
                    margin-bottom: 0;
                    text-align: left;
                }
            }

            .checkbox-square {
                width: 30px;
                height: 30px;
                background-color: #eaedee;
                margin-bottom: 0;
                margin-right: 14px;
                cursor: pointer;

                + label {
                    cursor: pointer;
                }
            }

            input[type="checkbox"]:checked + .checkbox-square {
                background-color: $gold;

                &::after {
                    content: "\f00c";
                    font-family: "FontAwesome";
                    color: $secondary;
                }
            }

            .login-btn-container {
                border-top: 2px solid #ddd;
                padding-top: 20px;

                @include media-breakpoint-up(sm) {
                    border-top: none;
                    padding-top: 0;
                }
            }

            .login-btn,
            .register-btn {
                padding: 8px 24px;
                font-size: 16px;
                font-weight: 600;
                line-height: 40px;
                width: 100%;
                box-shadow: 0px 0px 40px rgba(13, 28, 61, 0.15);
                @include transition;

                @include media-breakpoint-up(sm) {
                    width: auto;
                }

                &:hover {
                    background-color: $base !important;
                    @include transition;
                }
            }

            .register-btn {
                @include media-breakpoint-up(sm) {
                    width: 100%;
                }
            }
        }

        .register-box {
            padding-bottom: 0;
            border-bottom: 0;
            border-top: 2px solid #ddd;
            padding-top: 20px;

            .checkbox-container {
                &:first-child {
                    margin-bottom: 14px;
                }

                &:last-child {
                    margin-bottom: 36px;
                }

                .checkbox-label {
                    width: 90%;
                }
            }
        }

        .social-login-container {
            button {
                border-radius: 40px;
                border: none;
                width: 100%;
                font-weight: 600;
                margin-bottom: 20px;
                border: 2px solid $gold;
                padding: 6px 24px;
                @include transition;

                @include media-breakpoint-up(sm) {
                    line-height: 40px;
                }

                &:hover {
                    background-color: $gold !important;
                    color: $secondary !important;
                    @include transition;
                }

                svg {
                    font-size: 24px;
                    margin-right: 10px;
                }
            }
        }
    }
}
