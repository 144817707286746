.newsletter {
    background: url(../../assets/images/newsletter.png);
    background-repeat: no-repeat;
    background-position: 35%;
    background-size: cover;
    padding: 40px 20px;

    @include media-breakpoint-up(lg) {
        padding: 62px 20px;
        background-position: center;
    }

    .content {
        max-width: 1160px;
        margin: auto;

        .main-title {
            font-weight: 600;
            max-width: 300px;
            margin-bottom: 20px;

            @include media-breakpoint-up(lg) {
                font-size: 60px;
                line-height: 78px;
                font-weight: 700;
                max-width: 650px;
                margin-bottom: 34px;
            }
        }

        .subtitle {
            font-size: 20px;
            line-height: 25px;
            margin-bottom: 29px;
            font-weight: 400;
            max-width: 400px;

            @include media-breakpoint-up(lg) {
                font-size: 30px;
                line-height: 39px;
                max-width: 635px;
            }
        }

        .subscribe-form {
            input[type="text"],
            input[type="email"] {
                display: block;
                padding: 10px 15px;
                line-height: 40px;
                border: none;
                width: 100%;
                max-width: 334px;
                margin-bottom: 16px;

                @include media-breakpoint-up(lg) {
                    width: 334px;
                }

                &::placeholder {
                    color: $lightBlue;
                }

                &:focus {
                    outline: none;
                }
            }

            .checkbox-container {
                margin-bottom: 20px;

                @include media-breakpoint-up(lg) {
                    margin-bottom: 38px;
                }

                .checkbox-square {
                    width: 30px;
                    height: 30px;
                    background-color: $secondary;
                    margin-bottom: 0;
                    margin-right: 14px;
                    cursor: pointer;

                    + label {
                        cursor: pointer;
                    }
                }

                input[type="checkbox"]:checked + .checkbox-square {
                    background-color: $base;

                    &::after {
                        content: "\f00c";
                        font-family: "FontAwesome";
                        color: $secondary;
                    }
                }

                .checkbox-label {
                    font-weight: 300;
                }
            }
        }

        .send-btn {
            padding: 8px 18px;

            font-size: 16px;
            font-weight: 700;
            @include transition;

            @include media-breakpoint-up(lg) {
                line-height: 40px;
                padding: 8px 24px;
            }

            &:hover {
                background-color: $base !important;
                @include transition;
            }
        }
    }
}
