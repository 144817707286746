.adminsidebar {
    background-color: $base;
    padding: 0 15px;

    .logo {
        margin: 30px auto;
        text-align: center;
        max-height: 40px;
        max-width: 100%;
    }

    .sidebar-link {
        font-size: 16px;
        font-weight: bold;
        padding: 10px;
        justify-content: center;

        &:last-child {
            margin-bottom: 30px;

            @include media-breakpoint-up(md) {
                margin-bottom: 0;
            }
        }

        @include media-breakpoint-up(md) {
            margin-top: 10px;
            justify-content: left;
        }

        &:hover {
            text-decoration: none;
        }
        .before-icon {
            display: inline;
            color: $gold;
        }
        .active-icon {
            display: none;
        }
        .sidebar-linktext {
            display: inline;
            cursor: pointer;
            color: white;
            transition: $transition;

            &:hover {
                text-decoration: none;
                color: $gold !important;
                transition: $transition;
            }
        }
    }

    .active {
        background-color: $base;
    }
}
