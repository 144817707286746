.reservations,
.users {
    .reservation-item {
        cursor: pointer;
    }

    input {
        outline: none;
        font-size: 1rem !important;

        &::placeholder{
            color: $lightBlue;
        }
    }
}
