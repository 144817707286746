.edit-user-data {
    @include media-breakpoint-up(xl) {
        padding: 80px 0;
    }

    .main-title {
        font-size: 30px;
        font-weight: 700;
        line-height: 40px;
        margin-bottom: 40px;

        @include media-breakpoint-up(lg) {
            font-size: 40px;
            margin-bottom: 60px;
            line-height: 52px;
        }
    }

    .userdatas {
        .userdatas-row {
            margin-bottom: 6px;
            display: flex;
            align-items: center;
        }

        .key {
            font-size: 16px;
            font-weight: 700;
            line-height: 40px;
            width: 35%;
            max-width: 73px;
            margin-right: 8px;
            margin-bottom: 0;
            text-transform: uppercase;
        }

        .angle-down {
            cursor: pointer;
        }

        input {
            border: 0;
            border-bottom: 1px solid $lightBlue;
            width: 70%;
            padding: 0 5px;
            margin: 5px 0 20px;
        }

        .btn {
            color: white;
            padding: 4px 10px;

            margin-left: 10px;
            border: 0;
            font-size: 16px;
            font-weight: 600;
            transition: all 0.2s linear;

            &:hover {
                background-color: $base !important;
                @include transition;
            }
        }
    }
}
