.text-editors {
    .editor-tabs {
        margin: 50px auto;
    }

    .button-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .save-button {
            margin: 15px auto;
        }

        .error-message{
            color: $warning;
        }
    }

    .preview {
        margin: 50px auto;
    }

    @media screen and (max-width: 768px) {
        .w-md-editor-toolbar {
            height: 90px !important;
        }
    }
}
