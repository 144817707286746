.admin-login {
    .admin-header {
        background: linear-gradient(180deg, rgba(13, 28, 61, 0.6) 0%, rgba(13, 28, 61, 0) 47.4%), url(../../assets/images/main.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        height: 94px;

        img {
            max-height: 40px;
        }
    }

    .login-container {
        margin-top: 130px;

        @include media-breakpoint-up(md){
            margin-top: 180px;
        }

        .title-row {
            text-align: center;
            margin-bottom: 30px;
        }

        .form-row {
            max-width: 450px;
            margin: auto;
        }

        button:hover {
            background-color: $gold !important;
            border-color: $gold;
        }
    }
}
