footer {
    padding-top: 51px;

    .logo {
        object-fit: cover;
        height: 40px;
    }

    .row {
        max-width: 1160px;
        margin: auto;
        padding: 0 20px 46px 20px;

        @include media-breakpoint-up(lg) {
            padding: 0 0 46px 0;
        }

        > div {
            margin-bottom: 15px;

            @include media-breakpoint-up(sm) {
                margin-bottom: 0;
            }

            &:first-child {
                margin-bottom: 40px;

                @include media-breakpoint-up(xl) {
                    padding-left: 0;
                }
            }

            &:last-child {
                margin-top: 20px;
                margin-bottom: 0;

                @include media-breakpoint-up(sm) {
                    margin-top: 5px;
                }
                @include media-breakpoint-up(lg) {
                    padding-left: 0;
                }
                @include media-breakpoint-up(xl) {
                    padding-left: 15px;
                }
            }
        }

        .col-lg-2 {
            @include media-breakpoint-up(lg) {
                flex: 0 0 20%;
                max-width: 20%;
            }
        }

        .footer-title {
            font-size: 22px;
            line-height: 40px;
            font-weight: 500;
            margin-bottom: 8px;
        }

        ul li {
            font-size: 16px;
            font-weight: 300;
            line-height: 20.8px;
            padding: 8px 0;

            a {
                @include transition;
            }

            a.text-secondary:hover {
                color: $gold !important;
                text-decoration: none;
                @include transition;
            }
        }

        .language-container {
            p.hu {
                margin-right: 20px;
                position: relative;

                &::after {
                    content: "";
                    width: 1px;
                    height: 18px;
                    background-color: $secondary;
                    display: inline-block;
                    position: absolute;
                    right: -10px;
                    top: 2px;
                }
            }

            p {
                cursor: pointer;
            }

            p.active {
                color: $gold !important;
            }
        }

        .login-btn {
            border-radius: 40px;
            padding: 16px 25.5px;
            font-size: 16px;
            font-weight: 600;
            @include transition;
            margin-top: -15px;

            &:hover {
                background-color: $lightBlue !important;
                @include transition;
                text-decoration: none;
            }
        }
    }

    .bottom-footer-container {
        padding: 23px 35px 20px 35px;

        .bottom-footer {
            max-width: 1160px;
            margin: auto;

            .copyright {
                font-size: 16px;
                font-weight: 400;
                line-height: 25px;

                @include media-breakpoint-up(md) {
                    line-height: 40px;
                }
            }

            .social {
                margin-top: 10px;

                @include media-breakpoint-up(md) {
                    margin-top: 0;
                }

                p {
                    font-weight: 600;

                    @include media-breakpoint-up(sm) {
                        margin-right: 18px;
                    }

                    svg {
                        margin-left: 12px;
                    }
                }

                .icon.text-secondary {
                    border-radius: 50%;
                    width: 26px;
                    height: 26px;
                    @include transition;

                    &:hover {
                        background-color: $gold !important;
                        color: $secondary !important;
                        @include transition;
                    }
                }
            }
        }
    }
}
