.reservation-details-modal {
    max-width: 600px;
    color: $base !important;

    .feedback {
        width: 100%;
        text-align: center;
        font-weight: 300;
        margin-top: 20px;
        font-size: 20px;
    }

    .reservation-header {
        padding: 10px;
    }
    .modal-content {
        padding: 10px;

        .modal-title {
            font-size: 20px;
            line-height: 40px;
            font-weight: 700;
            color: $base;
        }

        .modal-body {
            .title {
                font-size: 16px;
                line-height: 16px;
                font-weight: 600;
                margin-bottom: 16px;
                color: $base;
            }

            .dropdown-title {
                cursor: pointer;
                color: $base;

                &:hover {
                    color: $gold;
                }
            }

            .detail-box {
                padding-bottom: 16px;
                margin-bottom: 16px;
                border-bottom: 2px solid #ddd;

                .detail-row {
                    padding: 5px 0;

                    .payment-data-small{
                        font-size: 12px;
                    }
                }

                .detail-icon {
                    color: $lightBlue;

                    svg {
                        margin: auto;
                    }
                }

                .detail-name {
                    font-weight: bold;
                    color: $base;
                    text-transform: uppercase;
                }

                .detail-content {
                    margin: 10px 0px 10px 15px;
                }
            }
        }
    }
}

.button-row {
    width: 100%;
    button {
        width: 100%;
        outline: none;

        &.confirm {
            &:hover {
                background-color: $gold;
                border-color: $gold;
            }
        }

        &.cancel{
            border-color: $lightBlue;
            color: $lightBlue;

            &:hover{
                border-color: $gold;
                color: $gold;
            }
        }
    }
}

.feedback {
}
