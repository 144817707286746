// Colors //
$base: #0d2862;
$secondary: #fff;
$gold: #dab782;
$lightBlue: #bbcbd9;

$theme-colors: (
    "primary": #0d2862,
    "secondary": #fff,
    "warning": #dab782,
    "light": #bbcbd9,
);

// Screen sizes //
$big-desktop: 1649px;
$small-desktop: 1350px;
$desktop: 1199px;
$desktop-max: 1200px;
$tablet: 991px;
$tablet-max: 992px;
$small-tablet: 767px;
$small-tablet-max: 768px;
$big-mobile: 639px;
$big-mobile-max: 640px;
$mobile: 479px;
$mobile-max: 480px;

$transition: 0.3s;
